<template>
    <div class="container">
        <div class="banner">
            <img :src="brand?.details?.banner">
        </div>
        <div class="about">
            <div class="navtitle">
                <div class="title">{{ brand.title }}</div>
                <div class="sub_title">{{ brand.e_title }}</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="about_content">
                <p>{{ brand.intro }}</p>
                <div class="img_list">
                    <div class="img_list_item" v-for="(item,index) in imgList" :key="index" :style="{flex: item.flex}" @mouseover="mouseoverImg(index)">
                        <img v-lazy="item.src" :title="item.title">
                    </div>
                </div>
            </div>
        </div>
        <div class="advantage">
            <div class="main">
                <div class="navtitle">
                    <div class="title" style="color: #fff">核心优势</div>
                    <div class="sub_title" style="color: #fff;opacity: 0.6">CORE ADVANTAGES</div>
                    <div class="horizontal_line"></div>
                </div>
                <div class="card_list">
                    <div class="card_list_item" v-for="(item,index) in brand?.details?.adv" :key="index">
                        <img :src="item.icon">
                        <span class="title">{{ item.title }}</span>
                        <span class="text">{{ item.intro }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="exhibit">
            <div class="navtitle">
                <div class="title">房型展示</div>
                <div class="sub_title">ROOM TYPE DISPLAY</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="room_list">
                <div class="room_list_item" v-for="item in house_types" :key="item.fx_id" @click="showDetail(item.fx_id)">
                    <img v-lazy="item?.cover_picture?.path">
                    <div class="info">
                        <div>
                            <span class="title">{{ item.fx_name }}</span>
                            <div class="area">
                                <span>{{ item.acreage_min }}-{{ item.acreage_max }}㎡</span>
                                <span>|</span>
                                <span>{{ item.rental_type == 1?'整租':'合租' }}</span>
                            </div>
                            <div>
                                <span class="price">{{ item.price_min }}-{{ item.price_max }}</span>
                                <span class="unit">元/月</span>
                            </div>
                            <div class="configuration">
                                <div v-for="(item2,index) in item.appliances" :key="index">{{ item2 }}</div>
                            </div>
                        </div>
                        <div class="info_footer un_copy">
                            <span>查看详情</span>
                            <img src="../../assets/image/gd.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="facilities">
            <div class="main">
                <div class="navtitle">
                    <div class="title">基础设施</div>
                    <div class="sub_title">INFRASTRUCTURE</div>
                    <div class="horizontal_line"></div>
                </div>
                <div class="facilities_list">
                    <div class="facilities_list_item" v-for="(item,index) in iconList" :key="index">
                        <img :src="require(`@/assets/icon/${item.path}.png`)" >
                        <span>{{ item.name }}</span>
                    </div>
                </div>
            </div>
        </div>
        <room-detail ref="roomDetail" :isShow="isShow"/>
        <loading v-if="isLoading"/>
    </div>
</template>
<script>
import roomDetail from '@/components/roomDetail';
import { getBrandDetail } from '@/api/brand'
export default {
    name: 'brandDetail',
    components: {
        roomDetail
    },
    data() {
        return {
            imgList: [],
            iconList: [
                { name: '安全监控', path: 'aqjk' },
                { name: 'WIFI', path: 'wifi2' },
                { name: '便利店', path: 'bld' },
                { name: '停车场', path: 'tcc' },
                { name: '活动场地', path: 'hdcd' },
                { name: '电梯', path: 'dt' },
                { name: '地铁', path: 'ditie' },
                { name: '休闲区', path: 'xxq' },
                { name: '门禁', path: 'mj' },
                { name: '服务维修', path: 'fwwx' },
                { name: '娱乐', path: 'yule' },
                { name: '安保', path: 'anbao' },
                { name: '健身房', path: 'jsf' },
                { name: '公园', path: 'gy' },
            ],
            brand: {},
            house_types: [],
            isShow: false,
            isLoading: false
        }
    },
    watch: {
        $route: {
            handler(n) {
                this.initData(n.query.id)
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        async initData(id) {
            try {
                this.isLoading = true
                const { data:res } = await getBrandDetail(id)
                this.brand = res.brand
                this.house_types = res.house_types
                this.imgList = res.brand?.details?.images.map((item,index) => {
                    let data = {
                        src: item.src,
                        title: item.title,
                        flex: 0
                    }
                    if(!index) {
                        data.flex = 6
                    }else {
                        data.flex = 1
                    }
                    return data
                })
            }finally {
                this.isLoading = false
            }
        },
        mouseoverImg(index) {
            this.imgList.forEach((item,i) => {
                if(index == i) {
                    item.flex = 6
                }else {
                    item.flex = 1
                }
            })
        },
        showDetail(id) {
            this.isShow = true
            this.$refs.roomDetail.detailId = id
            this.$refs.roomDetail.translateX = 0
            this.$refs.roomDetail.current = 0
            this.$refs.roomDetail.initData()
        }
    }
}
</script>
<style scoped lang="scss">
$width: 1600px;
.container {
    box-sizing: border-box;
    width: 100vw;
    height: auto;
    .banner {
        width: 100%;
        height: 530px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .about {
        width: $width;
        margin: 0 auto;
        padding: 80px 50px;
        box-sizing: border-box;
        .about_content {
            display: flex;
            flex-direction: column;
            margin-top: 60px;
            color: #666666;
            font-size: 14px;
            p {
                margin-bottom: 40px;
                text-indent: 2em;
            }
            .img_list {
                display: flex;
                height: 500px;
                column-gap: 20px;
                &_item {
                    flex: 1;
                    transition: all 0.9s ease;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .advantage {
        width: 100%;
        height: 734px;
        background: url('../../assets/image/hxys_bg.png') no-repeat;
        background-position: center;
        background-size: cover;
        .main {
            width: $width;
            margin: 0 auto;
            padding: 80px 50px;
            box-sizing: border-box;
            .horizontal_line {
                background: rgba($color: #fff, $alpha: 0.33);
                &::after {
                    background: #fff;
                }
            }
        }
        .card_list {
            display: flex;
            column-gap: 20px;
            margin-top: 50px;
            &_item {
                flex: 1;
                border: 1px solid #EEEEEE;
                height: 380px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 28px;
                transition: all 0.3s ease;
                background: #fff;
                padding: 0 30px;
                font-size: 16px;
                text-align: center;
                box-sizing: border-box;
                line-height: 24px;
                &:hover {
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* 移入时的阴影效果 */
                }
                img {
                    width: 112px;
                    height: 112px;
                    object-fit: cover;
                    border-radius: 50%;
                }
                .title {
                    color: #333;
                    font-size: 18px;
                    font-weight: bold;
                    position: relative;
                    &::after {
                        content: '';
                        display: block;
                        width: 30px;
                        height: 4px;
                        background: #FFA71D;
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .text {
                    color: #666;
                    font-size: 16px;
                }
            }
        }
    }
    .exhibit {
        width: $width;
        margin: 0 auto;
        padding: 80px 50px;
        box-sizing: border-box;
        .room_list {
            margin-top: 60px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            cursor: pointer;
            &_item {
                height: 354px;
                display: flex;
                transition: 0.3s all ease;
                &:hover {
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
                }
                img {
                    width: 500px;
                    height: 100%;
                    object-fit: cover;
                }
                .info {
                    flex: 1;
                    background: #F5F5F5;
                    padding: 30px 20px 20px 20px;
                    box-sizing: border-box;
                    color: #666666;
                    font-size: 14px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .title {
                        color: #333;
                        font-size: 20px;
                        &:hover {
                            color: #FFA71D;
                        }
                    }
                    .area {
                        display: flex;
                        column-gap: 10px;
                        margin: 18px 0;
                    }
                    .price {
                        color: #FF4D4D;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .unit {
                        color: #333333;
                    }
                    .configuration {
                        display: flex;
                        flex-wrap: wrap;
                        column-gap: 12px;
                        margin-top: 18px;
                        row-gap: 10px;
                        div {
                            padding: 1px 5px;
                            box-sizing: border-box;
                            border: 1px solid #D9D9D9;
                            background: #F4F4F4;
                            border-radius: 4px;
                            color: #666;
                        }
                    }
                    &_footer {
                        display: flex;
                        column-gap: 10px;
                        align-items: center;
                        color: #333;
                        font-size: 16px;
                        img {
                            width: 18px;
                            height: 12px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
    .facilities {
        width: 100vw;
        background: #F5F5F5;
        .main {
            width: $width;
            margin: 0 auto;
            padding: 80px 50px;
            box-sizing: border-box;
            .facilities_list {
                margin-top: 60px;
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-row-gap: 50px;
                &_item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    row-gap: 16px;
                    color: #666;
                    font-size: 16px;
                    img {
                        width: 82px;
                        height: 82px;
                    }
                }
            }
        }
    }
}
</style>
